import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import FolderTree from "react-folder-tree";
import {
  getChildren,
  downloadLink,
  toggleChildren,
  addChildren,
} from "./utils";
import "react-folder-tree/dist/style.css";

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  link: {
    paddingTop: "25px",
    cursor: "pointer",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#2d8dd6ea",
  },
  content: {
    padding: "30px",
  },
}));

let cancelCallback = false;

function FileExplorer() {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState({});
  const classes = useStyles();

  useEffect(() => {
    axios
      .get("https://us-central1-btib-download-center.cloudfunctions.net/browse")
      .then((resp) => {
        setFiles({
          name: "root",
          isOpen: true,
          children: getChildren(resp.data).filter(({ name }) => name !== "img"),
        });
      });
  }, []);

  async function handleDisplayChildren(path, nodesList, display) {
    // Avoid callback race
    cancelCallback = true;

    const currentNode =
      path.length === 0 ? files : nodesList[nodesList.length - 1];

    if (currentNode.children?.length > 0) {
      setFiles(() => toggleChildren(files, path, display));
    } else {
      const apiPath = nodesList.map(({ name }) => name).join("/");

      const resp = await axios.get(
        "https://us-central1-btib-download-center.cloudfunctions.net/browse/" +
          apiPath
      );
      const children = getChildren(resp.data, apiPath + "/");

      setFiles(() => addChildren(files, path, children));
    }

    setTimeout(() => {
      cancelCallback = false;
    }, 500);
  }

  function getNodesListFromPath(path) {
    return path.reduce((nodes, id) => {
      const lastParent = nodes[nodes.length - 1] || files;

      return [...nodes, lastParent.children[id]];
    }, []);
  }

  function handleChange(_, e) {
    const { path, params, type } = e;

    if (type === "toggleOpen" && !cancelCallback) {
      const nodesList = getNodesListFromPath(path);
      handleDisplayChildren(path, nodesList, params[0]);
    }
  }

  async function handleNameClick(e) {
    const { path } = e.nodeData;

    const nodesList = getNodesListFromPath(path);

    const currentNode = nodesList[nodesList.length - 1];

    if (currentNode.link) {
      downloadLink(currentNode.link, currentNode.name);
    } else {
      handleDisplayChildren(path, nodesList);
    }
  }

  return (
    <>
      <Box display="flex" className={classes.root}>
        <Link className={classes.link} onClick={() => setOpen(true)}>
          See All Downloads
        </Link>
      </Box>
      <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box className={classes.content} display="flex">
          <FolderTree
            data={files}
            onChange={handleChange}
            onNameClick={handleNameClick}
            showCheckbox={false}
            initOpenStatus="custom"
            readOnly
          />
        </Box>
      </Dialog>
    </>
  );
}

FileExplorer.defaultProps = {};

FileExplorer.propTypes = {};

export default FileExplorer;
