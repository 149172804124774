import Module from "./Module";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FirestoreCollection } from "@react-firebase/firestore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import moment from "moment";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "1.75rem",
    fontWeight: "500",
    color: "grey",
    margin: "14px",
    marginBottom: "0",
  },
  version: {
    fontSize: "15px",
    marginLeft: "14px",
    margin: "0",
  },
}));

function sortModule(ma, mb) {
  return mb.priority - ma.priority;
}

function ModuleList(props) {
  const classes = useStyles();
  const { title, path, version, releaseDate } = props;

  return (
    <>
      <FirestoreCollection path={path} limit={20}>
        {(doc) =>
          doc.isLoading ? (
            <CircularProgress />
          ) : (
            <Box display="flex" flexDirection="column">
              <div>
                <h3 className={classes.title}>{title}</h3>
                {version && (
                  <p className={classes.version}>Version: {version}</p>
                )}
                {releaseDate && (
                  <p className={classes.version}>
                    Release date:{" "}
                    {moment(releaseDate.seconds * 1000).format("MM/DD/YYYY")}
                  </p>
                )}
              </div>
              <Grid container>
                <Grid container className={classes.root} item xs={12}>
                  {doc.value.sort(sortModule).map((data, id) => (
                    <Grid key={doc.ids[id]} item md={3} xs={12}>
                      <Module {...data} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          )
        }
      </FirestoreCollection>
      <Divider />
    </>
  );
}

ModuleList.defaultProps = {
  metadata: {},
};

ModuleList.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  metadata: PropTypes.object,
  version: PropTypes.string,
  releaseDate: PropTypes.object,
};

export default ModuleList;
