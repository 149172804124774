import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Fab from "@material-ui/core/Fab";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    margin: "15px",
    cursor: "pointer",
  },
  header: {
    height: "62px",
    "& .MuiCardHeader-title": {
      fontWeight: 600,
    },
  },
  action: {
    alignSelf: "center",
  },
  content: {
    textAlign: "center",
  },
  button: {
    boxShadow: "none",
    backgroundColor: "white",
    color: "#6b152d",
  },
}));

function Module({ icon, description, link, name }) {
  const classes = useStyles();

  function handleClick() {
    const a = document.createElement("a");
    a.href = link;
    a.download = link.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Card className={classes.root} onClick={handleClick}>
      <CardHeader
        className={classes.header}
        classes={{ action: classes.action }}
        title={name}
        avatar={<img src={icon} alt="logo" width="32" height="32" />}
        subheader={description}
        action={
          <Fab
            className={classes.button}
            href={link}
            size="small"
            aria-label="add"
          >
            <GetAppIcon />
          </Fab>
        }
      />
    </Card>
  );
}

Module.propTypes = {};

export default Module;
