/* Libs & plugins */
import { useEffect, useState } from "react";
import { FirestoreCollection } from "@react-firebase/firestore";
import ModuleList from "./modules/ModuleList";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import axios from "axios";

const useStyles = makeStyles(() => ({
  root: {
    padding: "15px",
  },
}));

function sortCategories(categoryA, categoryB) {
  return categoryB.data.priority - categoryA.data.priority;
}

function Categories({ path, token, user }) {
  const classes = useStyles();
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    if (token && user) {
      const options = {
        method: "GET",
        url: `https://active-framework.eu.auth0.com/api/v2/users/${user.sub}`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      };

      axios(options)
        .then(({ data }) => setMetadata(data.app_metadata || {}))
        .catch(console.error);
    }
  }, [token, user]);

  function filter(category) {
    const { packages = [] } = metadata;
    const { data = {} } = category;
    const restrictedKey = data["restricted_key"];

    if (!restrictedKey) {
      return true;
    }

    return packages.includes(restrictedKey);
  }

  return (
    <FirestoreCollection path={path} limit={10}>
      {(doc) => (
        <div className={classes.root}>
          {doc.isLoading ? (
            <CircularProgress />
          ) : (
            doc.value
              ?.map((data, id) => ({ data, id }))
              .filter(filter)
              .sort(sortCategories)
              .map(({ data, id }) => (
                <ModuleList
                  key={doc.ids[id]}
                  path={path + "/" + doc.ids[id] + "/items/"}
                  metadata={metadata}
                  title={data.name}
                  version={data.version}
                  releaseDate={data.releaseDate}
                />
              ))
          )}
        </div>
      )}
    </FirestoreCollection>
  );
}

Categories.propTypes = {
  path: PropTypes.string.isRequired,
  token: PropTypes.string,
  user: PropTypes.object,
};

export default Categories;
