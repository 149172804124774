import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <Auth0Provider
    domain="active-framework.eu.auth0.com"
    clientId="uwqiLlT4QluLwbCuIkg2JryEu0FMdB7B"
    redirectUri={window.location.origin}
    audience="https://active-framework.eu.auth0.com/api/v2/"
    scope="read:current_user read:current_user_metadata"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
