import { useEffect, useState } from "react";
import "./App.css";
import DownloadCenter from "./component/DownloadCenter";
import Menu from "./component/static/Menu";
import Categories from "./component/Categories";
import { FirestoreProvider } from "@react-firebase/firestore";
import firebase from "firebase";
import { useAuth0 } from "@auth0/auth0-react";
import FileExplorer from "./component/FileExplorer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Description from "./component/Description";

const config = {
  apiKey: "AIzaSyDNjpqoIdu5aDyNNPyE9acOgjehbbgFFMQ",
  authDomain: "btib-download-center.firebaseapp.com",
  databaseURL: "https://btib-download-center.firebaseio.com",
  projectId: "btib-download-center",
  storageBucket: "btib-download-center.appspot.com",
  messagingSenderId: "643957674627",
  appId: "1:643957674627:web:dc923225dc7087237d81b6",
  measurementId: "G-NX02SYNGF3",
};

function App() {
  const [token, setToken] = useState(null);
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: "https://active-framework.eu.auth0.com/api/v2/",
        scope: "read:current_user",
      })
        .then(setToken)
        .catch(console.error);
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

  return (
    <FirestoreProvider {...config} firebase={firebase}>
      <Menu />
      <DownloadCenter />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Description />
          <Categories path="/categories/" token={token} user={user} />
          <FileExplorer />
        </>
      )}
    </FirestoreProvider>
  );
}

export default App;
