import produce from "immer";

export function getChildren(data, prefix = "/") {
  const respFolders = data.folders.map((fold) => ({
    name: fold.endsWith("/") ? fold.slice(0, -1) : fold,
    isOpen: false,
    children: [],
  }));

  const respFiles = data.files.map((file) => ({ ...file }));

  return [...respFolders, ...respFiles].map((item) => ({
    ...item,
    // remove prefix
    name: item.name.startsWith(prefix)
      ? item.name.slice(prefix.length)
      : item.name,
  }));
}

export function downloadLink(link, name) {
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = link.startsWith("https://") ? link : "https://" + link;
  a.target = "blank";
  a.download = name;
  document.body.appendChild(a);
  a.click();
}

export const addChildren = produce((draft, path, children) => {
  let node = draft;
  path.forEach((id) => (node = node.children[id]));
  node.children = children;
  node.isOpen = true;
});

export const toggleChildren = produce((draft, path) => {
  let node = draft;
  path.forEach((id) => (node = node.children[id]));
  node.isOpen = !node.isOpen;
});
