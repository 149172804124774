import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  root: {
    height: "100px",
    backgroundColor: "#f7f7f7",
  },
  container: {
    height: "100%",
  },
  icon: {
    fontSize: "60px",
    alignSelf: "flex-end",
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: "500",
    margin: "0 14px",
    alignSelf: "flex-end",
  },
  text: {
    textAlign: "center",
    marginBottom: 0,
  },
}));

function DownloadCenter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.container} display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex" flexDirection="row">
            <CloudDownloadIcon className={classes.icon} fontSize="large" />
            <h2 className={classes.title}>Download Center</h2>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default DownloadCenter;
