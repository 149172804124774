import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(() => ({
  appbar: {
    minHeight: "50px",
  },
  toolbar: {
    backgroundColor: "#6b152d",
    minHeight: "50px",
    paddingLeft: "0",
  },
  iconButton: {
    padding: 0,
  },
  logo: {
    marginLeft: "49px",
    marginRight: "37px",
  },
  link: {
    color: "#fff",
    fontSize: "14px",
    fontFamily:
      "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
    padding: "15px",
    position: "relative",

    "&:hover": {
      textDecoration: "none",

      "&:after": {
        content: "' '",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "4px",
        background: "rgba(255,255,255,0.79)",
        position: "absolute",
        float: "left",
      },
    },
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Menu() {
  const classes = useStyles();

  return (
    <AppBar className={classes.appbar} position="static">
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.iconButton}
          edge="start"
          href="https://doc.active-framework.com/"
          color="inherit"
          aria-label="menu"
        >
          <img
            className={classes.logo}
            src="/img/logo.png"
            width="105"
            height="40"
            alt=""
          />
        </IconButton>
        <Link className={classes.link} href="https://doc.vayandata.com">
          Documentation
        </Link>
        <Link className={classes.link} href="https://elearning.vayandata.com">
          E-learning
        </Link>
        <Link className={classes.link} href="#">
          Downloads
        </Link>
        <Link className={classes.link} href="https://forum.vayandata.com">
          Forum
        </Link>
        <Link
          className={classes.link}
          href="https://licensing.active-framework.com"
        >
          Licensing
        </Link>
        <Link className={classes.link} href="https://vayandata.com/contact-us">
          Contact
        </Link>
      </Toolbar>
    </AppBar>
  );
}
