/* Libs & plugins */
import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { FirestoreCollection } from "@react-firebase/firestore";

const useStyles = makeStyles(() => ({
  warning: {
    width: "80%",
    margin: "auto",
    marginTop: "15px",
    backgroundColor: "rgba(245, 227, 2, 0.1)",
    border: "2px solid rgba(245, 227, 2)",
    borderRadius: "5px",
    padding: "5px",
  },
  warningIcon: {
    fill: "rgba(245, 227, 2)",
  },
  titleBlock: {
    display: "flex",
  },
  title: {
    margin: "0 5px",
  },
  description: {
    whiteSpace: "pre-wrap",
    margin: "3px",
  },
}));

function Description(props) {
  const classes = useStyles();

  return (
    <FirestoreCollection path="/info/">
      {(doc) =>
        doc.isLoading ? (
          <CircularProgress />
        ) : (
          doc.value
            ?.filter((data) => data.description)
            .map((data, id) => (
              <div key={id} className={classes.warning}>
                <div className={classes.titleBlock}>
                  <WarningIcon className={classes.warningIcon} />
                  <h3 className={classes.title}>{data.title}</h3>
                </div>
                <p className={classes.description}>
                  {data.description.replaceAll("\\n", "\n")}
                </p>
              </div>
            ))
        )
      }
    </FirestoreCollection>
  );
}

Description.defaultProps = {};

Description.propTypes = {};

export default Description;
